import {Injectable} from '@angular/core';

import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {DataStorageService} from './data-storage.service';
import {ToastService} from './toast.service';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class RequestService {
  config: any;
  loading: any;

  constructor(private httpClient: HttpClient,
              private dataStorageService: DataStorageService,
              private loadingController: LoadingController,
              private toastService: ToastService
  ) {
  }

  getHeaders() {
    return new Promise(resolve => {
      Promise.all([
        this.dataStorageService.loadData('Auth-User'),
        this.dataStorageService.loadData('Auth-Token')
      ]).then(userData => {
        const authData: any = userData;

        console.log('userData:');
        console.log(userData);

        const headers = new HttpHeaders().set('Auth-User', String(authData[0]))
          .append('Auth-Token', String(authData[1])
            // .append(  'Content-Type', 'application/json') // why is this causing problems ???
          );
        resolve(headers);
      });
    }).catch(console.log);
  }

  async post(url, requestData) {
    this.loading = await this.loadingController.create({
      message: 'Loading data',
    });
    url = this.config.serverurl + url;
    return new Promise(resolve => {

      this.getHeaders().then(headerData => {
        const headers: any = headerData;
        this.httpClient.post(url, requestData, {headers}).subscribe((data: any) => {
          if (data.hasOwnProperty('error')) {
            console.log(data);
            this.toastService.showToast(data.error, 'bottom', this.toastService.colorStyle.danger);
          }
          resolve(data);
        }, err => {
          console.log(err);
          this.toastService.showToast(err.message, 'bottom', this.toastService.colorStyle.danger);
          resolve(err);
        });
      });
    });
  }

  async postWithoutHeaders(url, requestData) {
    this.loading = await this.loadingController.create({
      message: 'Loading data',
    });
    url = this.config.serverurl + url;
    return new Promise(resolve => {

      this.getHeaders().then(headerData => {
        const headers: any = headerData;
        this.httpClient.post(url, requestData).subscribe((data: any) => {
          if (data.hasOwnProperty('error')) {
            console.log(data);
            this.toastService.showToast(data.error, 'bottom', this.toastService.colorStyle.danger);
          }
          resolve(data);
        }, err => {
          console.log(err);
          this.toastService.showToast(err.message, 'bottom', this.toastService.colorStyle.danger);
          resolve(err);
        });
      });
    });
  }

  async get(url) {
    url = this.config.serverurl + url;
    return new Promise(resolve => {
      this.getHeaders().then(headerData => {
        const headers: any = headerData;
        console.log('headerData:');
        console.log(headerData);
        this.httpClient.get(url, {headers}).subscribe((data: any) => {
          if (data.hasOwnProperty('error')) {
            console.log(data);
            this.toastService.showToast(data.error, 'bottom', this.toastService.colorStyle.danger);
          }
          resolve(data);
        }, err => {
          console.log(err);
          this.toastService.showToast(err.message, 'bottom', this.toastService.colorStyle.danger);
          resolve(err);
        });
      });
    });
  }

  async delete(url) {
    url = this.config.serverurl + url;
    return new Promise(resolve => {
      this.getHeaders().then(headerData => {
        const headers: any = headerData;
        this.httpClient.delete(url, {headers}).subscribe((data: any) => {
          if (data.hasOwnProperty('error')) {
            console.log(data);
            this.toastService.showToast(data.error, 'bottom', this.toastService.colorStyle.danger);
          }
          resolve(data);
        }, err => {
          console.log(err);
          this.toastService.showToast(err.message, 'bottom', this.toastService.colorStyle.danger);
          resolve(err);
        });
      });
    });
  }

  loadConfig() {
    if (this.config) {
      return Promise.resolve(this.config);
    }
    return new Promise(resolve => {
      return this.httpClient.get<any[]>('assets/config/config.json').subscribe(data => {
        this.config = data;
        resolve(data);
      }, err => {
        console.log(err);
        this.toastService.showToast(err.message, 'bottom', this.toastService.colorStyle.danger);
        resolve(err);
      });
    });
  }
}
